
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { useState } from "react";
import { ChakraProvider } from '@chakra-ui/react'
import Basics from "./components/Basics";

import Blackbord from "./pages/Blackbord";
import Chat from "./pages/Chat";

import History from "./pages/History";


const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };

  return (

    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Blackbord />} />
        <Route path="/1" element={<Basics />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/history" element={<History />} />



      </Routes>


    </BrowserRouter>


  );
};

export default App;
