import { useMemo, useEffect } from "react";
import { io } from "socket.io-client";

export const useSocket = (server) => {
    const socket = useMemo(() => io(server, { transports: ["websocket"] }), [server]);

    useEffect(() => {
        return () => {
            socket.disconnect(); // Cleanup when the component unmounts
        };
    }, [socket]);

    return socket; // Return only the socket instance
};
