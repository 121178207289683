
import { useCallback, useEffect, useRef, useState } from "react";
import { useSocket } from "../../Hooks/useSocket";
import {
  Box,
  Button,
  Input,
  VStack,
  Text,
  HStack,
  IconButton,
  Textarea,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import DOMPurify from "dompurify";

// var md = require('markdown-it')();
import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import { CodeXmlIcon, NetworkIcon } from "lucide-react";

import { GrCloudlinux } from "react-icons/gr";
import NameModal from "../../components/Userenter";

function Chatsocket() {

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const inputRef = useRef();
  const socket = useSocket("https://chatsocket.lmlmd.xyz")
  const [saveid, setsaveid] = useState("")
  // const [imgs, setimgs] = useState([])
  // const { socket } = useSocket("http://localhost:4000/")
  // const { socket } = useSocket("wss://chatsocket.lmlm.online")


  const {
    isOpen: isidOpen,
    onOpen: onidOpen,
    onClose: onidClose,
  } = useDisclosure();


  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {

    const hash = crypto.randomUUID();

    const id = localStorage.getItem('id')
    setsaveid(id)

    if (!id) {
      localStorage.setItem('id', hash)
      setsaveid(hash)
    }




  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (e) => {
    e.preventDefault()
    // if (!input.trim()) return;
    // const userMessage = { text: input, sender: "user" };
    // setMessages([...messages, userMessage]);
    // setInput("");

    // // Simulating AI response (Replace with actual AI API call)
    // setTimeout(() => {
    //   const botMessage = { text: "This is an AI response!", sender: "bot" };
    //   setMessages((prevMessages) => [...prevMessages, botMessage]);
    // }, 1000);
    sendMessage(e)
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSend(event);
    }
  };

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = useCallback(() => {
    socket.on("server:getMessages", (messages) => {
      setMessages(messages);
    });
  }, []);

  const onEnterPress = (e) => {

    if (e.keyCode == 13 && e.shiftKey == false) {
      console.log(message)
      e.preventDefault();
      chatgpt(e)
    }
  }

  const sendMessage = (e) => {
    e.preventDefault()
    socket.emit("client:addMessage", { message, hour: Date.now(), id: saveid })
    setMessage("")
    inputRef.current.focus();

  };

  const clearMessage = (e) => {
    e.preventDefault()
    socket.emit("client:clear", {})
  };


  const chatgpt = (e) => {
    e.preventDefault()
    console.log(message.length > 1)
    socket.emit("client:addMessage", { message, hour: Date.now(), id: saveid })
    socket.emit("client:chatgpt", { message, hour: Date.now(), id: saveid });
    // message = "";
    setMessage("")

  };


  const itexamanswers = (e) => {
    e.preventDefault()
    console.log(message.length > 1)
    socket.emit("client:addMessage", { message, hour: Date.now(), id: saveid })
    socket.emit("client:itexamanswers", { message, hour: Date.now(), id: saveid });
    // message = "";
    setMessage("")

  };

  const itexamanswersonly = (e) => {
    e.preventDefault()
    console.log(message.length > 1)
    socket.emit("client:addMessage", { message, hour: Date.now(), id: saveid })
    socket.emit("client:itexamanswersonly", { message, hour: Date.now(), id: saveid });
    // message = "";
    setMessage("")

  };

  const ccna = (e) => {
    e.preventDefault()
    console.log(message.length > 1)
    socket.emit("client:addMessage", { message, hour: Date.now(), id: saveid })
    socket.emit("client:ccna", { message, hour: Date.now(), id: saveid });
    // message = "";
    setMessage("")

  };

  const customNetwork = (e) => {
    setMessage("Host IP Address	Addr. Class	Network Address	Host Address	Network Broadcast Address	Default Subnet Mask ");
  };





  const expacn = (e) => {
    setMessage("I will give you questions I want the answer without clarification")
  }


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  const handlePaste = async (e) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      const base64 = await convertBase64(fileObject)
      //image.pngsetfiles(base64)
      socket.emit("client:addMessage", { src: base64, hour: Date.now(), id: saveid })

    } else {
      alert(
        "No image data was found in your clipboard. Copy an image first or take a screenshot."
      );
    }
  };


  return (
    <>
      <Box
        p={5}
        // maxW="600px"
        mx="auto"
        // mt={10}
        borderWidth={1}
        borderRadius="lg"
        shadow="md"
      >
        <VStack spacing={4} align="stretch" >
          <Box rounded={"3xl"}>
            <HStack>
              <Textarea
                id="prompt-textarea"
                ref={inputRef}
                tabindex="0"
                style={{ color: "black", width: "100%", height: "20px" }}
                type="text" name="message" value={message} onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type a message..."
                onPaste={handlePaste}
              />
              {/* <Button colorScheme="blue" onClick={handleSend}>
                Send
              </Button> */}
            </HStack>
            <HStack py={2} spacing={6}>
              <IconButton aria-label="Search database" icon={<DeleteIcon />} onClick={clearMessage} />
              <IconButton aria-label="Search database" icon={<GrCloudlinux />} onClick={chatgpt} />
              <IconButton aria-label="Search database" icon={<NetworkIcon />} onClick={itexamanswers} />
              <IconButton aria-label="Search database" icon={<CodeXmlIcon />} onClick={itexamanswersonly} />
            </HStack>
          </Box>

          <Box h="200px" overflowY="auto" borderWidth={1} p={3} borderRadius="md">
            {/* {
              messages.map((message, i) => (
                <li key={i} className="list-group-item">
          

                  <div dangerouslySetInnerHTML={{ __html: message.message }} />


                  <img src={message.src || ""} ></img>
                </li>
              ))


            } */}
            {messages.map((message, i) => (
              <HStack
                key={i}
                justify={message.id === saveid ? "flex-end" : "flex-start"}
              >
                <Box
                  bg={message.id === saveid ? "blue.500" : "gray.200"}
                  color={message.id === saveid ? "white" : "black"}
                  px={4}
                  py={2}
                  borderRadius="lg"
                >
                  {/* <Text>{message.message}</Text> */}

                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.message) }} />

                  {/* <Text>{message.message.split('\n').map(val => <Text>{val}</Text>)}</Text> */}
                  {message?.src && (
                    <Image src={message.src || ""} />
                  )}

                </Box>
              </HStack>
            ))}
            <div ref={messagesEndRef} />
          </Box>
        </VStack>
      </Box>

      {/* <NameModal isOpen={!saveid ? true: false} onClose={onidClose}  setid={setsaveid}/> */}
    </>
  )
}

export default Chatsocket
